import { Box, Button, Typography } from "@mui/material";
import React, { lazy } from "react";
import { useNavigate } from "react-router-dom";

const Svg_403 = lazy(()=>import('../static/illustrations/Svg_403'));

function _403() {

  const navigate = useNavigate();

  return (
    <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:`100%`}}>
      <Box sx={{flex:7, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <Svg_403/>
        <Typography variant="h3" sx={{my:2}}>Oops, You do not have privilege to view this page</Typography>
        <Button variant="contained" onClick={()=>{navigate('/')}}>Go Back Home</Button>
      </Box>
      {/* <Box sx={{flex:3,display:{xs:'none',md:'block'}, overflow:'hidden' }}>
        <Svg_404_Background/>
      </Box> */}
    </Box>
  );
}

export default _403;
