import axios from 'axios';
// config
import { HOST_API } from '../../config';
import NavigateUtils from './NavigateUtils';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});


const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      NavigateUtils(status)
    }
    return Promise.reject(error);
 }
);

export default axiosInstance;