import { Box, Card, Container, Stack, styled, Typography } from "@mui/material";
import Logo from "../../components/Logo";
import Page from "../../components/Page";
import useResponsive from "../../hooks/useResponsive";
import LoginForm from "../../sections/auth/login/LoginForm";
import useSettings from "../../hooks/useSettings";
import { lazy } from "react";
const LoginIllustration = lazy(()=>import('../../static/illustrations/illustration_login'))

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: '40px',
  zIndex: 9,
  lineHeight: 0,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent:'center',
  alignItems:'center',
  backgroundColor: theme.palette.mode === 'light'? theme.palette.primary.main : theme.palette.background.paper,
  color: theme.palette.primary.contrastText,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  const mdUp = useResponsive('up', 'md', 0, 0);

  return (
    <Page title="Login" meta={undefined}>
      <RootStyle>
        

        {mdUp && (
          <SectionStyle>
            <Box sx={{ }}>
              <Typography variant="h2">
                Let's make it
              </Typography>
              <Typography variant="h2">
                happen together!
              </Typography>
              
              <LoginIllustration sx={{ width: '100%', height: '100%', alignItems: 'center' }} />

            </Box>

          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
          <HeaderStyle>
          <Logo />
        </HeaderStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Welcome to EPR
                </Typography>
              
              </Box>


            </Stack>

            <LoginForm />

            <Box sx={{ position: 'absolute', bottom: '20px', textAlign: 'center' }}>
        <Typography variant='caption'>Powered by Infinite Cercle Private Limited </Typography>
      </Box>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
