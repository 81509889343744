//
import Button from './Button';
import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: any) {
  return Object.assign(
    //Card(theme),
    Button(theme),
  );
}