import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }: any) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = <Box sx={{ width: 80, height: 80, ...sx }}>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50" height="50" viewBox="0 0 2670.000000 2670.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,2670.000000) scale(0.100000,-0.100000)"
fill="#6f2da8" stroke="none">
<path d="M12855 26689 c-2723 -99 -5330 -1022 -7510 -2658 -535 -401 -933
-748 -1435 -1250 -498 -498 -842 -893 -1241 -1426 -2015 -2685 -2938 -6010
-2598 -9360 274 -2705 1366 -5258 3134 -7320 422 -493 977 -1048 1470 -1470
2117 -1815 4761 -2921 7535 -3154 416 -35 665 -45 1140 -45 805 0 1444 52
2215 180 2370 392 4626 1447 6460 3019 493 422 1048 977 1470 1470 975 1137
1766 2462 2310 3865 976 2520 1157 5322 515 7975 -487 2014 -1468 3927 -2825
5510 -422 493 -977 1048 -1470 1470 -2543 2179 -5808 3316 -9170 3194z m2487
-11205 c3 -3 -66 -290 -153 -637 -529 -2101 -492 -1947 -480 -1987 6 -21 27
-52 46 -69 l36 -31 265 0 264 0 -7 -27 c-3 -16 -45 -181 -92 -368 l-86 -340
-360 0 c-314 0 -368 2 -425 18 -198 54 -352 180 -450 369 -57 109 -80 207 -80
341 l0 109 301 1197 c165 658 311 1237 323 1286 13 50 27 102 30 118 l6 27
429 0 c235 0 430 -3 433 -6z m5733 -446 c66 -134 171 -350 236 -481 l116 -238
404 481 403 480 703 0 704 0 -153 -182 c-85 -101 -235 -280 -334 -398 -1013
-1210 -1204 -1440 -1204 -1443 0 -5 136 -285 370 -762 305 -621 389 -793 506
-1032 l123 -253 -665 0 -664 0 -191 388 c-105 213 -220 447 -256 520 l-64 132
-162 -193 c-89 -107 -283 -341 -432 -520 l-270 -326 -714 0 c-678 -1 -713 0
-700 16 8 10 48 58 90 108 856 1018 1651 1968 1655 1978 2 7 -77 179 -177 382
-99 204 -270 552 -379 775 -109 223 -238 486 -288 586 -49 100 -93 191 -97
203 l-6 21 664 0 664 0 118 -242z m-14985 -375 c0 -5 -47 -168 -103 -363
l-102 -355 -685 -6 c-675 -5 -686 -5 -760 -28 -105 -31 -184 -76 -258 -146
-101 -94 -162 -193 -222 -360 -29 -79 -33 -103 -34 -195 -2 -232 99 -395 286
-459 101 -35 226 -41 818 -41 449 0 570 -3 570 -12 -1 -7 -40 -161 -88 -343
l-87 -330 -770 0 c-704 0 -779 2 -875 18 -185 33 -313 84 -424 167 -109 82
-222 257 -262 405 -48 182 -18 567 66 838 176 565 631 1009 1191 1161 200 54
199 54 997 55 408 1 742 -2 742 -6z m2470 -16 c241 -72 430 -277 471 -511 18
-106 6 -194 -61 -448 -83 -312 -120 -391 -239 -509 -72 -71 -104 -94 -182
-133 -164 -80 -157 -79 -659 -84 -484 -4 -521 -1 -588 48 -55 40 -80 79 -99
155 -14 56 -14 76 -4 132 16 87 58 168 118 224 61 58 81 70 173 105 75 28 77
28 342 32 l268 3 11 42 c5 23 21 83 35 132 13 50 24 94 24 98 0 5 -152 7 -337
5 -307 -4 -345 -6 -416 -26 -207 -57 -365 -186 -456 -373 -89 -183 -110 -321
-72 -471 37 -145 98 -232 199 -286 123 -66 112 -65 910 -69 397 -3 722 -6 722
-8 0 -3 -23 -89 -51 -192 -27 -103 -69 -257 -91 -340 l-41 -153 -807 0 c-858
0 -938 4 -1115 50 -100 26 -223 86 -297 146 -117 93 -236 284 -279 449 -53
206 -27 559 62 827 186 564 710 1019 1311 1138 174 35 239 38 673 36 356 -2
428 -5 475 -19z m2420 18 c0 -6 -160 -714 -166 -737 -5 -15 -30 -17 -268 -20
l-263 -3 -49 -30 c-90 -56 -84 -39 -309 -946 -113 -453 -210 -843 -216 -866
l-12 -43 -423 0 c-297 0 -424 3 -424 11 0 27 481 1960 499 2006 137 346 363
540 721 617 50 11 165 15 488 15 232 1 422 -1 422 -4z m2925 -17 c-4 -13 -50
-176 -103 -363 l-98 -340 -684 -6 c-675 -5 -686 -5 -760 -28 -121 -36 -186
-76 -280 -171 -93 -93 -153 -194 -206 -345 -23 -68 -28 -96 -28 -190 0 -114
12 -170 57 -260 67 -134 182 -202 382 -225 50 -5 343 -10 663 -10 l574 0 -7
-29 c-3 -17 -43 -171 -89 -343 l-82 -313 -770 0 c-703 0 -778 2 -874 18 -325
58 -506 180 -625 422 -58 116 -75 185 -82 320 -15 294 53 637 177 902 209 445
630 799 1101 927 200 54 199 54 997 55 l742 1 -5 -22z m4243 7 c43 -9 115 -34
162 -57 148 -72 281 -226 336 -388 14 -42 19 -84 19 -175 0 -116 -2 -127 -64
-350 -35 -126 -73 -256 -86 -287 -91 -224 -315 -392 -571 -428 -43 -5 -253
-10 -483 -10 -450 0 -474 3 -544 61 -133 110 -117 363 33 503 28 26 79 60 113
76 115 54 159 60 424 60 186 0 245 3 247 13 17 56 66 247 66 256 0 10 -75 12
-337 8 -386 -5 -408 -8 -563 -84 -76 -38 -110 -62 -170 -123 -87 -88 -130
-156 -178 -283 -48 -127 -60 -228 -39 -339 29 -160 104 -276 216 -332 116 -59
112 -59 899 -63 l723 -4 -7 -32 c-3 -18 -44 -171 -90 -340 -46 -169 -84 -310
-84 -313 0 -3 -386 -3 -857 -2 -783 3 -866 5 -953 22 -108 21 -244 66 -310
104 -182 103 -331 313 -385 542 -48 202 -18 542 70 806 173 519 628 945 1180
1103 207 59 307 68 768 70 312 1 403 -2 465 -14z"/>

<path fill="#ffffff" d="M12855 26689 m2487
-11205 c3 -3 -66 -290 -153 -637 -529 -2101 -492 -1947 -480 -1987 6 -21 27
-52 46 -69 l36 -31 265 0 264 0 -7 -27 c-3 -16 -45 -181 -92 -368 l-86 -340
-360 0 c-314 0 -368 2 -425 18 -198 54 -352 180 -450 369 -57 109 -80 207 -80
341 l0 109 301 1197 c165 658 311 1237 323 1286 13 50 27 102 30 118 l6 27
429 0 c235 0 430 -3 433 -6z m5733 -446 c66 -134 171 -350 236 -481 l116 -238
404 481 403 480 703 0 704 0 -153 -182 c-85 -101 -235 -280 -334 -398 -1013
-1210 -1204 -1440 -1204 -1443 0 -5 136 -285 370 -762 305 -621 389 -793 506
-1032 l123 -253 -665 0 -664 0 -191 388 c-105 213 -220 447 -256 520 l-64 132
-162 -193 c-89 -107 -283 -341 -432 -520 l-270 -326 -714 0 c-678 -1 -713 0
-700 16 8 10 48 58 90 108 856 1018 1651 1968 1655 1978 2 7 -77 179 -177 382
-99 204 -270 552 -379 775 -109 223 -238 486 -288 586 -49 100 -93 191 -97
203 l-6 21 664 0 664 0 118 -242z m-14985 -375 c0 -5 -47 -168 -103 -363
l-102 -355 -685 -6 c-675 -5 -686 -5 -760 -28 -105 -31 -184 -76 -258 -146
-101 -94 -162 -193 -222 -360 -29 -79 -33 -103 -34 -195 -2 -232 99 -395 286
-459 101 -35 226 -41 818 -41 449 0 570 -3 570 -12 -1 -7 -40 -161 -88 -343
l-87 -330 -770 0 c-704 0 -779 2 -875 18 -185 33 -313 84 -424 167 -109 82
-222 257 -262 405 -48 182 -18 567 66 838 176 565 631 1009 1191 1161 200 54
199 54 997 55 408 1 742 -2 742 -6z m2470 -16 c241 -72 430 -277 471 -511 18
-106 6 -194 -61 -448 -83 -312 -120 -391 -239 -509 -72 -71 -104 -94 -182
-133 -164 -80 -157 -79 -659 -84 -484 -4 -521 -1 -588 48 -55 40 -80 79 -99
155 -14 56 -14 76 -4 132 16 87 58 168 118 224 61 58 81 70 173 105 75 28 77
28 342 32 l268 3 11 42 c5 23 21 83 35 132 13 50 24 94 24 98 0 5 -152 7 -337
5 -307 -4 -345 -6 -416 -26 -207 -57 -365 -186 -456 -373 -89 -183 -110 -321
-72 -471 37 -145 98 -232 199 -286 123 -66 112 -65 910 -69 397 -3 722 -6 722
-8 0 -3 -23 -89 -51 -192 -27 -103 -69 -257 -91 -340 l-41 -153 -807 0 c-858
0 -938 4 -1115 50 -100 26 -223 86 -297 146 -117 93 -236 284 -279 449 -53
206 -27 559 62 827 186 564 710 1019 1311 1138 174 35 239 38 673 36 356 -2
428 -5 475 -19z m2420 18 c0 -6 -160 -714 -166 -737 -5 -15 -30 -17 -268 -20
l-263 -3 -49 -30 c-90 -56 -84 -39 -309 -946 -113 -453 -210 -843 -216 -866
l-12 -43 -423 0 c-297 0 -424 3 -424 11 0 27 481 1960 499 2006 137 346 363
540 721 617 50 11 165 15 488 15 232 1 422 -1 422 -4z m2925 -17 c-4 -13 -50
-176 -103 -363 l-98 -340 -684 -6 c-675 -5 -686 -5 -760 -28 -121 -36 -186
-76 -280 -171 -93 -93 -153 -194 -206 -345 -23 -68 -28 -96 -28 -190 0 -114
12 -170 57 -260 67 -134 182 -202 382 -225 50 -5 343 -10 663 -10 l574 0 -7
-29 c-3 -17 -43 -171 -89 -343 l-82 -313 -770 0 c-703 0 -778 2 -874 18 -325
58 -506 180 -625 422 -58 116 -75 185 -82 320 -15 294 53 637 177 902 209 445
630 799 1101 927 200 54 199 54 997 55 l742 1 -5 -22z m4243 7 c43 -9 115 -34
162 -57 148 -72 281 -226 336 -388 14 -42 19 -84 19 -175 0 -116 -2 -127 -64
-350 -35 -126 -73 -256 -86 -287 -91 -224 -315 -392 -571 -428 -43 -5 -253
-10 -483 -10 -450 0 -474 3 -544 61 -133 110 -117 363 33 503 28 26 79 60 113
76 115 54 159 60 424 60 186 0 245 3 247 13 17 56 66 247 66 256 0 10 -75 12
-337 8 -386 -5 -408 -8 -563 -84 -76 -38 -110 -62 -170 -123 -87 -88 -130
-156 -178 -283 -48 -127 -60 -228 -39 -339 29 -160 104 -276 216 -332 116 -59
112 -59 899 -63 l723 -4 -7 -32 c-3 -18 -44 -171 -90 -340 -46 -169 -84 -310
-84 -313 0 -3 -386 -3 -857 -2 -783 3 -866 5 -953 22 -108 21 -244 66 -310
104 -182 103 -331 313 -385 542 -48 202 -18 542 70 806 173 519 628 945 1180
1103 207 59 307 68 768 70 312 1 403 -2 465 -14z"/>
</g>
</svg>
  </Box>;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
