import {createContext, useEffect, useReducer} from "react";
import PropTypes from "prop-types";
// utils
import axios from "../service/api/axiosConfig";
import {setSession} from "../utils/jwt";
import setFinancialYear from "../utils/financialYear";
import useSettings from "../hooks/useSettings";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export const initialState: AuthType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  hasBolckChan: false,
};

export type AuthType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  hasBolckChan: boolean;
  user?: userType | null;
};

type userType = {
  username: string;
  name?: string;
  email: string;
  mobile?: string;
  brandID: string;
  brandName: string;
  brandType: string;
  userType: string;
  blockChainEnabled: boolean;
  accessibility?: {
    modules?: moduleType[];
  };
};

export type moduleType = {
  moduleName: string;
  access?: string[];
};

const handlers: any = {
  INITIALIZE: (state: AuthType, action: any) => {
    const {isAuthenticated, user} = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: AuthType, action: any) => {
    const {user, hasBolckChan} = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      hasBolckChan: hasBolckChan,
    };
  },
  LOGOUT: (state: AuthType) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: AuthType, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({children}: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {isBlockchainEnabled} = useSettings();



  useEffect(() => {
  //console.log(state)

    if (!state.hasBolckChan) {
     localStorage.setItem('settings', '{"themeMode":"light"}')


      isBlockchainEnabled();
    }
  }, [state]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get("/management/user/my-account");
          const user = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
              hasBolckChan: user.blockChainEnabled,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    const response = await axios.post("/authentication-service/login", {
      username,
      password,
    });
    const {token}: {token: string} = response.data;
    setSession(token.replace("Bearer ", ""));
    setFinancialYear();

    const respons = await axios.get("/management/user/my-account");
    const user = respons.data;

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        hasBolckChan: user.blockChainEnabled,
      },
    });
  };

  const logout = async () => {
    setSession(null);
window.location.reload()
    localStorage.removeItem('settings')
    dispatch({type: "LOGOUT"});

  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {AuthContext, AuthProvider};
