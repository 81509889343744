function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/resetPassword"),
  verify: path(ROOTS_AUTH, "/verify"),
  forgotPassword:path(ROOTS_AUTH,'/forgotPassword')
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  delivery: {
    root: path('','/delivery'),
    create: path('/delivery', '/create'),
    view: (id: string) => path('',`/delivery/${id}`),
    edit: (id: string) => path('',`/delivery/edit/${id}`)

  },
  brandInvoice: {
    root: path('','/brandInvoice'),
    view: (id: string) => path('',`/brandInvoice/${id}`)
  },
  target: {
    root: path('/target','/dashboard'),
    create:path('/target','/create'),
    createNew:path('/target','/create/new'),
    edit: (id: string) => path('',`/target/edit/${id}`)
  },
  user: {
    profile: path(ROOTS_DASHBOARD,'/profile')
  },
  management:{
    user: path('/management', '/user'),
    userView: (id: string) => path('/management',`/user/${id}`),
    brand: path('/management', '/brand'),
    brandView: (id: string) => path('/management',`/brand/${id}`),
  },
    blog: {
      view: (id:string) => path('',`/blog/view/${id}`),
      update: (id: string) => path('',`/blog/update/${id}`),
      create: path('',`/blog/create`)
   },
   settings:{
    root:path('','/settings')
   },
   report:{
    root:path('','/report')
   }, 
   adminSettings:{
    root:path('/adminSettings', '/rawMaterials'), 
    rawMaterial:path('/adminSettings', '/rawMaterials'),
   }

};
