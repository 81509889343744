import { useRoutes } from 'react-router-dom';
import './App.css';
import Router from './router/routes';
import ThemeProvider from './theme';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <ThemeProvider>
      <CssBaseline enableColorScheme >
      <SnackbarProvider maxSnack={4}>
      <Router/>
      </SnackbarProvider>
      </CssBaseline>

    </ThemeProvider>
  );
}

export default App;
