import { alpha, PaletteOptions, ThemeOptions } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to left, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
  lighter: "#f1eaf6",
  light: "#b796d4",
  main: "#9225C5" ,
  dark: "#4e1f76",
  darker: "#381754",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#3F774E",
  light: "#AAF27F",
  main: "#53D575",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: '#fff',
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const STROKE = {
  main: createGradient("#AA2A92","#6029C2"),
};



const GRADIENTS = {
  primary: createGradient("#9A24C5", "#27239B"),
  primaryBg: `linear-gradient(to left,#522C73 50% ,#372176 41%)`,
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  successBg:`linear-gradient(to left,#3F774E 40% ,#2E6D3F 22%)`,
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  header:createGradient("#9C24C6","#EFB17D"),
  cardHeader:createGradient("#9625C5","#6C36D7"),
  graph:createGradient("#3550C6","#843BF2"),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#20B748", "#26d955", "#86eaa1", "#e7fbec"],
  yellow: ["#FFAB00", "#ffbe39", "#ffd071", "#ffe3aa"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
  grey: ["#24244F", "#2c2c61","#1b1b3a", "#919EAB",],
};

const darkPalette : PaletteOptions = {
  mode:"dark",
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,

  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  stroke:STROKE,
  text: { primary: '#FFFFFF', secondary: GREY[600], disabled: GREY[600] },
  background: { paper: "#111030", default: '#070926'},
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default darkPalette;
