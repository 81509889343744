import PropTypes from 'prop-types';
import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../router/paths';
// pages
import Login from '../view/auth/Login';
// components
import LoadingScreen from '../view/LoadingScreen';

// ----------------------------------------------------------------------

type AuthGuard = {
  children: ReactNode,
};

export default function AuthGuard({ children }: AuthGuard) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null >(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }
  

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}