
import { LoadingButton } from '@mui/lab';
import { Stack, InputAdornment, IconButton, TextField, Button, useTheme, } from '@mui/material';
import { FormEvent, useState } from 'react'
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../router/paths';


export default function LoginForm() {
  const { login } = useAuth();

  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [emailIsValid, setEmailIsValid] = useState(false);

  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [formInputError, setFormInputError] = useState({ email: "", password: "" });

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>('');

  const [password, setPassword] = useState<string>('');


  const emailValidationHandler = () => {
    if (email.trim().length === 0) {
      setEmailIsValid(true);
      setFormInputError((prev) => ({
        ...prev,
        email: "Email address is required",
      }))
    }

    let regex: RegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

    if (!regex.test(email) && email.trim().length !== 0) {
      setEmailIsValid(true);
      setFormInputError((prev) => ({
        ...prev,
        email: "Enter valid email address",
      }))
    }
  }

  const passwordValidationHandler = () => {
    if (password.trim().length === 0) {
      setPasswordIsValid(true);
      setFormInputError((prev) => ({
        ...prev,
        password: "Password is required"
      }))
    }

    if ((password.trim().length < 8 || password.trim().length >= 32) && password.trim().length !== 0) {
      setPasswordIsValid(true)
      setFormInputError((prev) => ({
        ...prev,
        password: "Password should be at least 8 characters long and cannot exceed 32 characters",
      }))
    }
  }


  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email.trim().length === 0 || password.trim().length === 0) {
      emailValidationHandler();
      passwordValidationHandler();
      return;
    } else if (emailIsValid || passwordIsValid) {
      return
    }

    try {
      setIsSubmitting(true)
      // @ts-ignore
      await login(email, password);
      setIsSubmitting(false)
      navigate(PATH_DASHBOARD.root)

    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        enqueueSnackbar("Invalid Credentials, Kindly try again", { variant: 'error' })
      } else {
        enqueueSnackbar("Unable to login", { variant: 'error' })
      }
      setIsSubmitting(false)

    }

  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3}>

        <TextField name="email" label="Email address"
         value={email} 
         onChange={(e) => { setEmail(e.target.value) }} 
         error={emailIsValid} onBlur={emailValidationHandler}
         onFocus={() => { setEmailIsValid(false); setFormInputError((prev) => ({ ...prev, email: "" })) }} 
         helperText={formInputError.email} />


        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => { setPassword(e.target.value) }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={passwordIsValid}
          onFocus={() => { setPasswordIsValid(false); setFormInputError((prev) => ({ ...prev, password: "", passwordNotValid: "" })) }}
          onBlur={passwordValidationHandler}
          helperText={formInputError.password}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link> */}
      </Stack>
      {/* onClick={() => { onSubmit() }} */}
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} >
        Login
      </LoadingButton>
      <Button variant='outlined' size='large' sx={{ width: '100%', mt: '3%' }} onClick={() => navigate(PATH_AUTH.forgotPassword)}>Forgot Password</Button>
    </form>
  );
}