import React from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth';
import { PATH_AUTH } from '../../router/paths'

export default function NavigateUtils(StatusCode:any) {
    const { logout} = useAuth();
    
  const navigate=useNavigate()
  if(StatusCode===401){
    navigate(PATH_AUTH.login)
    logout();
}

}
