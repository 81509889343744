import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider, ThemeWithProps, ThemeOptions } from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows,{customShadows} from './shadows';
import useSettings from '../hooks/useSettings';
import darkPalette from './dark/darkpalette';
import darkTypography from './dark/darkTypography';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }: any) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const themeOptions : ThemeOptions = useMemo(
    () => ({
      palette : isLight ? palette : darkPalette,
      shape: { borderRadius: 8 },
      typography : isLight ? typography : darkTypography,
      
      customShadows
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}