import { ReactNode, } from 'react'
import { moduleType } from '../context/JWTContext';
import useAuth from '../hooks/useAuth'
import _403 from '../view/_403';

function PrivilegeGuard({moduleName, access, children}:{moduleName:string, access:string, children:ReactNode}) {
const {user}= useAuth();
//console.log(user);

    let moduleSuccessCheck:moduleType|undefined=user?.accessibility?.modules?.find((item:moduleType)=>item.moduleName===moduleName)
    if(moduleSuccessCheck){
       let accessCheckSuccess= moduleSuccessCheck.access?.find((item:string)=>item===access)
    if(accessCheckSuccess===access){
       return <>{children}</>
    }
    }


return  <><_403/></>

}

export default PrivilegeGuard