import { lazy, Suspense } from 'react';
import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import AuthGuard from '../guard/AuthGuard';
import PrivilegeGuard from '../guard/PrivilegeGuard'
// layouts
import LoadingScreen from '../view/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const BaseLayout = Loadable(lazy(()=>import('../layout/BaseLayout/BaseLayout')))
const MenuBar= Loadable(lazy(()=>import('../layout/MenuBarLayout/MenuBarLayout')))
//const Dashboard = Loadable(lazy(() => import('../view/dashboard/Dashboard')))
const Dashboard = Loadable(lazy(() => import('../view/dashboard/Index')))


const DeliveryList = Loadable(lazy(() => import('../view/Delivery/DeliveryList')))
const DeliveryDetails = Loadable(lazy(() => import('../view/Delivery/DeliveryDetails')))
const DeliveryView = Loadable(lazy(() => import('../view/Delivery/DeliveryView')))
const DeliveryEdit = Loadable(lazy(() => import('../view/Delivery/DeliveryEdit')))
const DeliveryCreate = Loadable(lazy(() => import('../view/Delivery/DeliveryCreate')))

const BrandInvoiceView = Loadable(lazy(() => import('../view/Brand/invoice/BrandInvoiceView')))
const BrandInvoiceList = Loadable(lazy(() => import('../view/Brand/invoice/BrandInvoiceList')))

const TargetDashboard = Loadable(lazy(() => import('../view/Targets/Dahboard/Index')))
const TargetCreate = Loadable(lazy(() => import('../view/Targets/TargetCreation')))
const TargetCreateNew=Loadable(lazy(()=>import('../view/Targets/TargetCreationNew')))
const TargetEdit = Loadable(lazy(() => import('../view/Targets/TargetEdit')))
const TargetEditV2=Loadable(lazy(()=>import('../view/Targets/TargetEditV2')))

const UserList = Loadable(lazy(() => import('../view/ApplicationManagement/UserList')));
const UserView = Loadable(lazy(() => import('../view/ApplicationManagement/UserDetails')));
const BrandList = Loadable(lazy(() => import('../view/ApplicationManagement/BrandList')));
const BrandView = Loadable(lazy(() => import('../view/ApplicationManagement/BrandDetails')));

const BlogPost = Loadable(lazy(() => import('../view/dashboard/Blog/BlogPost')))
const BlogPostCreate = Loadable(lazy(() => import('../view/dashboard/Blog/BlogNewPost')))

const Settings= Loadable(lazy(()=>import('../view/Settings/Settings')))

const Reports=Loadable(lazy(()=>import('../view/Reports/Reports')))

const RawMaterial= Loadable(lazy(()=>import('../view/AdminSettings/RawMaterial/RawMaterial')))

const LoginPage= Loadable(lazy(()=>import('../view/auth/Login')))
const NewLoginPage= Loadable(lazy(()=>import('../view/auth/NewLogin')))
const ForgotPasswordPage= Loadable(lazy(()=>import('../view/auth/ForgotPassword')))
const ResetPasswordPage= Loadable(lazy(()=>import('../view/auth/ResetPassword')))

const Page403= Loadable(lazy(()=>import('../view/_403')))
const Page404= Loadable(lazy(()=>import('../view/_404')))


export default function Router() {
  return useRoutes([
  {
    path: '', element: <Navigate to="/dashboard" replace />, index: true
  },
  { path: 'login', element: <LoginPage /> },
  { path: 'newlogin', element: <NewLoginPage /> },
  { path: 'forgotPassword', element: <BaseLayout/>, children:[{path:'', element:<ForgotPasswordPage/>}] },
  { path: 'resetPassword', element: <BaseLayout/>, children:[{path:'', element: <ResetPasswordPage />}] },

  {
    path: 'dashboard',
    element:
      <AuthGuard>
        <MenuBar />
      </AuthGuard>,
    children: [
      {
        path: '',
        element:  <Dashboard />
      },

    ]
  },
  {
    path: 'delivery',
    element:
     <AuthGuard>
       <MenuBar />,
     </AuthGuard>,
    children: [
      { element: <Navigate to="/delivery/list" replace />, index: true },
      { path: 'create', element:<PrivilegeGuard moduleName='delivery' access='create'><DeliveryCreate /></PrivilegeGuard>  },
      { path: 'list', element:  <PrivilegeGuard moduleName='delivery' access='read'><DeliveryList /></PrivilegeGuard> },
      { path: ':id', element:<PrivilegeGuard moduleName='delivery' access='read'> <DeliveryView /></PrivilegeGuard> },
      { path: 'edit/:id', element:<PrivilegeGuard moduleName='delivery' access='update'> <DeliveryEdit /> </PrivilegeGuard>},
    ],
  },
  {
    path: 'brandinvoice',
    element:
    <AuthGuard>
      <MenuBar />
    </AuthGuard>,
    children: [
      { element: <Navigate to="/brandinvoice/list" replace />, index: true },
      { path: 'list', element:<PrivilegeGuard moduleName='invoice' access='read'> <BrandInvoiceList /></PrivilegeGuard> },
      { path: ':id', element: <PrivilegeGuard moduleName='invoice' access='read'> <BrandInvoiceView /></PrivilegeGuard> },
    ],
  },
  {
    path: 'target',
    element:
    <AuthGuard>
      <MenuBar />
    </AuthGuard>,
    children: [
      { element: <Navigate to="/target/dashboard" replace />, index: true },
      { path: 'dashboard', element:<PrivilegeGuard moduleName='target' access='read'> <TargetDashboard /> </PrivilegeGuard>},
      { path: 'create/old', element:<PrivilegeGuard moduleName='target' access='create'>  <TargetCreate /> </PrivilegeGuard> },
      {path:'create', element:<TargetCreateNew/>},
      { path: 'edit/:poNumber', element: <PrivilegeGuard moduleName='target' access='update'> <TargetEditV2 /> </PrivilegeGuard> },
    { path: 'edit/old/:poNumber', element:<PrivilegeGuard moduleName='target' access='update'><TargetEdit/></PrivilegeGuard>}
    ],
  },
  {
    path: 'management',
    element:
    <AuthGuard>
      <MenuBar />
    </AuthGuard>,
    children: [
      { path: 'user', element: <PrivilegeGuard moduleName='user' access='read'><UserList /></PrivilegeGuard>, children: [{ path: ':id', element: <UserView /> }] },
      { path: 'brand', element: <PrivilegeGuard moduleName='brand' access='read'><BrandList /></PrivilegeGuard>, children: [{ path: ':id', element: <BrandView /> }] }
    ]
  },
  {
    path: 'blog',
    element:
    <AuthGuard>
      <MenuBar />
    </AuthGuard>,
    children: [
     { path: 'view/:id', element: <BlogPost /> },
      //{ path: 'view', element: <BlogPost /> },
      { path: 'create', element: <BlogPostCreate /> }

    ],
  },
  {
    path:'settings',
    element:<AuthGuard>
    <MenuBar/>
    </AuthGuard>,
    children:[
      {
        path:'',element:<Settings/>
      }
    ]
  },
  {
    path:'report',
    element: <AuthGuard>
    <MenuBar/>
    </AuthGuard>,
    children:[
      {
        path:'',
        element:<Reports/>
      }
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: 'adminSettings',
    element:
     <AuthGuard>
       <MenuBar />,
     </AuthGuard>,
    children: [
      { element: <Navigate to="/adminSettings/rawMaterials" replace />, index: true },
     { path: 'rawMaterials', element:<RawMaterial />  },
    ],
  },
])
}