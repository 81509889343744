
export const HEADER = {
  MOBILE_HEIGHT: 50,
  MAIN_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 60,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ALLOCATE = {
  BASE_WIDTH: 410
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 22,
};

export const defaultSettings = {
  themeMode: "light",
};


export const CONTRACT_ADDRESS = '0x6ac0e76dBbFB49220D070FA69F5Ee338f3124f84'

//Dev Server
//export const HOST_API = 'https://epr.thinktrash.co/api/epr'

//QA Server
//export const HOST_API = 'http://eprqa.thinktrash.co/api/epr'

//Prod Server
export const HOST_API = 'https://epr.cerclex.com/api/epr'

//Local Server
//export const HOST_API = 'http://localhost/api/epr'