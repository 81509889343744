import PropTypes from "prop-types";
// icons
import { Icon } from "@iconify/react";

// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

interface Iconify {
  icon: string,
  width?: number,
  height?: number,
  onClick?: Function,
  id?: string,
  [x:string]: any
};

export default function Iconify({ id, icon, width, onClick, ...other }: Iconify) {
  return <Box id={id} component={Icon} onClick={()=>{onClick && onClick()}} icon={icon} {...other} />;
}
